<template>
  <div class="content" v-if="IdValue === 1">
    <!-- 头部 -->

    <div class="toAddService">
      <div>服务订单</div>
      <div @click="toService">购买增值服务</div>
    </div>
    <div class="order-input">
      <div class="order-content">
        <ul class="tabBox">
          <li
            class="tabItem"
            v-for="(item, index) in orderContent"
            :key="item.id + index"
            :class="item.id === currentActive ? 'active' : ''"
            @click.self="changeTab(item)"
          >
            {{ item.title }}
          </li>
        </ul>
      </div>
      <!-- 搜索栏 -->
      <div class="content-input">
        <el-input
          v-focus
          v-model="keyCode"
          placeholder="请输入服务单号查询"
          class="input-with-select"
          @keydown.enter="searchEnterFun(keyCode)"
          clearable
          @clear="handelClear"
        >
          <template #append>
            <el-button class="input-icon" @click="handelSearch(keyCode)">
              <img src="../../../assets//images/search.png" alt=""
            /></el-button>
          </template>
        </el-input>
      </div>
    </div>
    <!-- 内容区域 -->
    <div v-if="orderData">
      <div
        class="content-theme"
        v-for="(item, index) in orderData"
        :key="item.id + index"
      >
        <div style="width: 885px; height: 42%">
          <div class="theme-1">
            <div>
              <img
                src="../../../assets/images/order/dzf.png"
                v-if="item.orderState === '待支付'"
                alt=""
              />
              <img
                src="../../../assets/images/order/dfk.png"
                v-if="item.orderState === '待发货'"
                alt=""
              />
              <img
                src="../../../assets/images/order/dfh.png"
                v-if="item.orderState === '已发货'"
                alt=""
              />
              <img
                src="../../../assets/images/order/wc.png"
                v-if="item.orderState === '已完成'"
                alt=""
              />
              <img
                src="../../../assets/images/order/wc.png"
                v-if="item.orderState === '已退货'"
                alt=""
              />
              <img
                src="../../../assets/images/order/qx.png"
                v-if="item.orderState === '已取消'"
                alt=""
              />
              <img
                src="../../../assets/images/order/dsh.png"
                v-if="item.orderState === '待审核'"
                alt=""
              />
              <span>{{ item.orderState }}</span>
            </div>
            <div class="rightText">
              {{ serviceArr.find((v) => v.value === item.type).label }}
            </div>
          </div>
          <div class="theme-2">
            <div class="theme-3">
              <p class="child1">订单编号：{{ item.orderNumber }}</p>
              <!--              <p class="child2" v-if="item.receiver">-->
              <!--                {{ item.receiver }} / {{ item.mobile }}-->
              <!--              </p>-->
              <!--              <p v-else></p>-->
            </div>
            <div class="theme-4">
              <span
                class="nth-1"
                v-if="
                  item.orderState === '待支付' || item.orderState === '已取消'
                "
                >需付款：</span
              >
              <span class="nth-1" v-else>实付金额：</span
              ><span class="nth-2">￥{{ item.amount }}</span>
            </div>
          </div>
        </div>
        <div class="border"></div>
        <div class="btn-price">
          <div class="btn-price-1">
            <div style="display: flex">
              <div class="btn-img">
                <img :src="item.picture" alt="" />
              </div>
              <div class="btn-price-1-1">
                <p class="price-p" :title="item.productName">
                  {{ item.productName }}
                </p>
                <div class="btn-price-s">
                  <p class="price-d">商品单价：￥{{ item.price }}</p>
                  <p class="price-l">商品数量：{{ item.count }}</p>
                  <p class="price-e">
                    <span class="price-e-1">商品金额：</span>
                    <span class="price-e-2">￥{{ item.goodsAmount }}</span>
                  </p>
                </div>
              </div>
            </div>
            <div class="btn-price-2">
              <p
                class="btn-price-2-2"
                v-if="item.orderState === '待支付'"
                @click="handleUnpaid(item)"
              >
                立即支付
              </p>
              <p class="btn-price-2-2" @click="handelCheckOut(item)">
                订单详情
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <orderCustomer v-model="showCustom" />
    <noContent style="margin-left: -80px" v-if="orderData == ''" />
    <div class="demo-pagination-block" v-if="orderLists.total">
      <el-pagination
        :current-page="orderLists.current"
        page-size="10"
        :small="small"
        background
        layout="total, prev, pager, next, jumper"
        :total="orderLists.total"
        next-text="下一页"
        prev-text="上一页"
        @current-change="handleCurrentChange"
      />
      <el-button class="btn-cl">确定</el-button>
    </div>
    <div v-if="orderLists.total === ''"></div>
  </div>

  <ServiceDetails
    @changeId="handleChangeId"
    :numValue="numValue"
    v-if="IdValue === 2"
  >
  </ServiceDetails>
</template>

<script setup>
import { ref, onMounted, reactive } from "vue";
import orderCustomer from "@/components/orderCustomer";
import OrderDetails from "./OrderDetails";
import ServiceDetails from "./serviceDetails.vue";
import oosPath from "@/global-variable";
import { addedList } from "../../../api/orderCenter/index.js";
import { expired } from "../../../api/order/index";
import { ElMessage, ElMessageBox } from "element-plus";
import noContent from "../../../components/directives/noContent";
import { useRouter } from "vue-router";
import emitter from "@/utils/eventBus";

const keyCode = ref("");
const currentActive = ref(1);
const IdValue = ref(1);
const orderContent = ref([
  { title: "全部订单", num: "", id: 1 },
  { title: "待支付", num: 0, id: 2 },
  { title: "服务中", num: 1, id: 6 },
  { title: "已完成", num: 2, id: 5 },
  { title: "已取消(支付超时)", num: 3, id: 3 },
  { title: "待审核", num: 4, id: 4 },
]);

const serviceArr = [
  { label: "延保服务", value: 0 },
  { label: "保养维修", value: 1 },
  { label: "驻场服务", value: 2 },
  { label: "硬件升级", value: 3 },
];
const dialogOpen = ref(false);
const showCustom = ref(false);
emitter.on("confimCustomClose", (val) => {
  showCustom.value = false;
});
emitter.on("customClose", (val) => {
  showCustom.value = false;
  getOrderList();
});

// 订单详情
const numValue = ref("");
const handelCheckOut = (item) => {
  numValue.value = item.id;
  IdValue.value = 2;
};
// 子组件传递给父组件
const handleChangeId = (i) => {
  IdValue.value = i;
  getOrderList();
};

// 我的订单列表
const dataValue = reactive({
  pageNum: 1,
  pageSize: 10,
  orderState: "",
  orderNumber: "",
});
const orderData = ref([]);
const orderLists = ref({});
const getOrderList = async () => {
  try {
    let picPath = oosPath.ossPath;
    let data = await addedList(dataValue);
    orderData.value = data.data.list;
    orderLists.value = data.data;
    orderData.value.forEach((item) => {
      if (item.orderState === 0) {
        item.orderState = "待支付";
      } else if (item.orderState === 1) {
        item.orderState = "服务中";
      } else if (item.orderState === 2) {
        item.orderState = "已完成";
      } else if (item.orderState === 3) {
        item.orderState = "已取消(支付超时)";
      } else if (item.orderState === 4) {
        item.orderState = "待审核";
      }
      item.picture = picPath + "/order" + item.picture;
    });
  } catch (error) {
    console.log(error);
  }
};
// 切换状态
const changeTab = (item) => {
  currentActive.value = item.id;
  dataValue.orderState = item.num;
  getOrderList(dataValue.orderState);
};
// 搜索
const searchEnterFun = (item) => {
  dataValue.orderNumber = item;
  getOrderList(dataValue.orderNumber);
};
// 搜索按钮
const handelSearch = (item) => {
  dataValue.orderNumber = item;
  getOrderList(dataValue.orderNumber);
};
// 清除
const handelClear = () => {
  dataValue.orderNumber = "";
  getOrderList(dataValue.orderNumber);
};

// 分页
const handleCurrentChange = (Num) => {
  dataValue.pageNum = Num;
  getOrderList(dataValue.pageNum);
};
// 待支付
const router = useRouter();
const handleUnpaid = async (item) => {
  let orderNumber = item.orderNumber;
  try {
    let data = await expired(orderNumber);

    let TimeNum = data.data;
    if (TimeNum === true) {
      ElMessage.error("订单已超时");
    } else {
      await router.push({
        path: "payorder",
        query: { orderNumber: orderNumber, isService: 1 },
      });
    }
  } catch (error) {
    console.log(error);
  }
};

const offTitle = () => {
  dialogOpen.value = false;
  getOrderList();
};

const toService = () => {
  router.push({
    path: "/support",
  });
};

onMounted(() => {
  getOrderList();
});
</script>

<style lang="less" scoped>
.toAddService {
  display: flex;
  padding-bottom: 20px;
  box-sizing: border-box;
  justify-content: space-between;
  align-items: center;
  font-size: 25px;

  & > div:nth-child(2) {
    margin-right: 25px;
    display: inline-block;
    padding: 10px 20px;
    font-size: 15px;
    color: white;
    background-color: rgba(241, 97, 34); /* Element UI 的主色 */
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s;
  }

  & > div:nth-child(2):hover {
    background-color: rgba(241, 97, 34, 0.8); /* 悬停效果 */
  }

  & > div:nth-child(2):active {
    background-color: rgba(241, 97, 34, 0.5); /* 点击效果 */
  }
}

.content {
  .order-input {
    display: flex;
    justify-content: space-between;

    .order-content {
      // display: flex;
      .tabBox {
        display: flex;
        margin-top: 10px;

        .tabItem {
          font-size: 15px;
          font-weight: 400;
          color: #666666;
          border-right: 1px solid #e1e1e1;
          padding: 0 15px;
          cursor: pointer;

          &:first-child {
            padding-left: 0;
          }

          &:last-child {
            border-right: none;
          }
        }

        .active {
          //background: lighten(#ce1200, 50%);
          color: #ce1200;
        }

        :hover {
          //background: lighten(#ce1200, 50%);
          color: #ce1200;
        }
      }
    }

    .content-input {
      margin-right: 25px;
      width: 320px;

      ::v-deep [data-v-73d05719] .el-input__wrapper {
        background: transparent;
        border: 0px solid #d9d9d9 !important;
        border-radius: 5px;
      }

      ::v-deep .el-input__wrapper.is-focus {
        background: transparent;
        border: 1px solid #d9d9d9 !important;
        border-radius: 5px;
      }

      .input-with-select {
        height: 40px;

        ::v-deep .el-input__inner {
          padding-left: 10px !important;
        }

        .input-icon {
          // background: #f2f2f2;
          display: inline-block;
          margin-bottom: 3px;

          img {
            display: inline-block;
            width: 16px;
            height: 16px;
          }
        }
      }
    }
  }

  .content-theme {
    width: 885px;
    /* height: 192px; */
    border: 1px solid #aaaaaa;
    margin-top: 33px;

    .theme-1 {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-left: 30px;

      img {
        display: inline-block;
        width: 24px;
        height: 24px;
        margin-right: 5px;
        margin-top: -5px;
      }

      span {
        display: inline-block;
        font-size: 18px;
        margin-top: 20px;
        font-weight: 400;
        color: #ce1200;
      }

      .rightText {
        padding: 17px 20px 0 0;
        color: #1dc779;
      }
    }

    .theme-2 {
      display: flex;
      justify-content: space-between;
      align-items: baseline;
      width: 900px;

      .theme-3 {
        margin-left: 31px;
        display: flex;

        .child1 {
          font-size: 12px;
          font-weight: 400;
          color: #666666;
          margin-right: 36px;
        }

        .child2 {
          font-size: 12px;
          font-weight: 400;
          color: #666666;
        }
      }

      .theme-4 {
        margin-right: 31px;

        .nth-1 {
          font-size: 12px;
          font-weight: 400;
          color: #666666;
          text-align: center;
        }

        .nth-2 {
          display: inline-block;
          font-size: 24px;
          font-weight: bold;
          color: #ce1200;
          align-items: center;
        }
      }
    }

    .border {
      border-bottom: 1px solid #e1e1e1;
    }

    .btn-price {
      .btn-price-1 {
        display: flex;
        justify-content: space-between;

        .btn-img {
          width: 80px;
          height: 80px;
          margin: 15px 26px 16px 32px;

          img {
            width: 80px;
            height: 80px;
          }
        }

        .btn-price-1-1 {
          .price-p {
            width: 370px;
            font-size: 12px;
            font-weight: 400;
            color: #333333;
            margin-top: 37px;
            margin-bottom: 8px;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 1;
            overflow: hidden;
          }

          .btn-price-s {
            display: flex;
            align-items: baseline;

            .price-d {
              width: 150px;
              font-size: 12px;
              font-weight: 400;
              color: #333333;
            }

            .price-l {
              font-size: 12px;
              font-weight: 400;
              color: #333333;
              margin-left: 36px;
            }

            .price-e {
              margin-left: 36px;
              height: 18px;
              line-height: 18px;

              .price-e-1 {
                font-size: 12px;
                font-weight: 400;
                color: #333333;
              }

              .price-e-2 {
                font-size: 24px;
                font-weight: bold;
                color: #333333;
              }
            }
          }
        }

        .btn-price-2 {
          margin-top: 20px;
          margin-right: 30px;

          .btn-price-2-2 {
            width: 120px;
            height: 30px;
            line-height: 28px;
            border: 1px solid #aaaaaa;
            text-align: center;
            font-size: 12px;
            font-weight: 400;
            color: #666666;
            margin-bottom: 10px;
            cursor: pointer;
            .hoverBorder();
          }

          .btn-price-2-3 {
            width: 120px;
            height: 30px;
            line-height: 30px;
            border: 1px solid #aaaaaa;
            text-align: center;
            font-size: 12px;
            font-weight: 400;
            color: #666666;
            margin-top: 10px;
            cursor: pointer;
            .hoverBorder();
          }
        }
      }

      .customerorder-1 {
        width: 884px;
        margin-bottom: 31px;

        .customerorder-2 {
          width: 766px;
          height: 32px;
          line-height: 32px;
          display: flex;
          align-items: center;
          margin: 1px 0 4px 87px;

          img {
            width: 20px;
            height: 20px;
          }

          .customerorder-3 {
            font-family: Microsoft YaHei;
            font-weight: 400;
            font-size: 16px;
            color: #333333;
            margin-left: 6px;
          }
        }

        .customerorder-4 {
          width: 766px;
          height: 78px;
          background: #ffffff;
          border-radius: 5px;
          border: 1px solid #d9d9d9;
          margin-left: 87px;
          margin-bottom: 12px;
          position: relative;

          .customerorder-4-1 {
            width: 309px;
            font-family: Microsoft YaHei;
            font-weight: bold;
            font-size: 16px;
            color: #333333;
            position: absolute;
            top: 28px;
            left: 61px;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
          }

          .customerorder-4-2 {
            font-family: Microsoft YaHei;
            font-weight: 400;
            font-size: 15px;
            color: #666666;
            position: absolute;
            top: 28px;
            left: 434px;
          }

          .customerorder-4-3 {
            font-family: Microsoft YaHei;
            font-weight: 400;
            font-size: 15px;
            color: #666666;
            position: absolute;
            top: 28px;
            left: 496px;
          }

          .customerorder-4-4 {
            position: absolute;
            top: 14px;
            right: 83px;
          }

          .customerorder-4-6 {
            width: 129px;
            height: 15px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            font-size: 13px;
            color: #ce1200;
            position: absolute;
            top: 48px;
            right: 34px;
          }

          .customerorder-4-8 {
            width: 100px;
            height: 32px;
            line-height: 32px;
            text-align: center;
            background: #ffffff;
            border-radius: 5px;
            border: 1px solid #ce1200;
            font-family: Microsoft YaHei;
            font-weight: 400;
            font-size: 12px;
            color: #ce1200;
            cursor: pointer;
            position: absolute;
            top: 12px;
            right: 52px;
          }

          .customerorder-4-9 {
            width: 16px;
            height: 16px;
            margin-right: 4px;
          }

          .customerorder-4-10 {
            font-family: Microsoft YaHei;
            font-weight: 400;
            font-size: 13px;
            color: #f8392d;
          }

          .customerorder-5 {
            width: 260px;
            height: 70px;
            margin: 58px 0 0 61px;
            display: flex;

            .customerorder-one {
              height: 70px;
              width: 108px;
              margin-right: 44px;

              .customerorder-5-1 {
                width: 48px;
                height: 48px;
                border: 1px solid #e1e1e1;
                margin: 0 auto;
              }

              .customerorder-5-2 {
                text-align: center;
                width: 108px;
                font-family: Microsoft YaHei;
                font-weight: 400;
                font-size: 12px;
                color: #999999;
                margin-top: 4px;
                white-space: nowrap;
                text-overflow: ellipsis;
                overflow: hidden;
              }
            }
          }

          .customerorder-8-1 {
            position: absolute;
            top: 20px;
            right: 6px;
            height: 50px;
            width: 60px;
            align-items: center;
            display: flex;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 2;
            overflow: hidden;
          }

          .customerorder-8 {
            position: absolute;
            top: 15px;
            right: 78px;

            .customerorder-8-2 {
              width: 48px;
              height: 48px;
              border: 1px solid #e1e1e1;
            }
          }

          .customerorder-6-3 {
            position: absolute;
            top: 38px;
            right: 83px;
          }

          .customerorder-6-4 {
            position: absolute;
            top: 77px;
            right: 26px;
            width: 144px;
            height: 35px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            font-size: 13px;
            color: #ce1200;
            text-align: center;
          }

          .customerorder-6 {
            width: 48px;
            height: 70px;
            margin-right: 24px;

            .customerorder-6-1 {
              width: 48px;
              height: 48px;
              border: 1px solid #e1e1e1;
            }

            .customerorder-6-2 {
              margin-top: 4px;
              text-align: center;
              width: 48px;
              font-family: Microsoft YaHei;
              font-weight: 400;
              font-size: 12px;
              color: #999999;
              white-space: nowrap;
              text-overflow: ellipsis;
              overflow: hidden;
            }
          }
        }
      }
    }
  }

  .demo-pagination-block {
    width: 980px;
    display: flex;
    justify-content: center;
    margin-top: 40px;

    .btn-cl {
      width: 65px;
      height: 35px;
      margin-left: 10px;
      font-size: 14px;
    }
  }
}

::v-deep .el-input-group__append {
  background: #f2f2f2;
}

::v-deep .el-input__wrapper.is-focus {
  box-shadow: none;
  border: 1px solid #d9d9d9;
}

::v-deep .el-divider--horizontal {
  margin: 0;
}

::v-deep .el-input__wrapper {
  width: 240px;
  background-color: #f2f2f2f2;
}

// ::v-deep .el-input__wrapper.is-focus {
//   box-shadow: 0 0 0 1px #999;
// }
::v-deep .el-pagination.is-background .el-pager li {
  border: 1px solid #d9d9d9;
  border-radius: 5px;
  background: transparent;
}

::v-deep .el-pagination.is-background .el-pager li.is-active {
  background-color: #ce1200;
  color: #fff;
  border: 1px solid #ce1200;
}

::v-deep .el-pager li:hover {
  color: #ce1200;
  border: 1px solid #ce1200;
}

::v-deep .el-pagination button:hover {
  color: #ce1200;
  border: 1px solid #ce1200;
}

::v-deep .el-pagination.is-background .btn-next {
  background: transparent;
  border: 1px solid #d9d9d9;
  border-radius: 5px;
}

::v-deep .el-pagination.is-background .btn-prev {
  background: transparent;
  border: 1px solid #d9d9d9;
  border-radius: 5px;
}

::v-deep .el-input__wrapper {
  background: transparent;
  border: 1px solid #d9d9d9;
  border-radius: 5px;
}

::v-deep .el-button:focus,
.el-button:hover {
  color: #ce1200;
  border-color: #ce1200;
  background-color: transparent;
  outline: 0;
}
</style>
