import request from "@/utils/request";

// 我的订单列表
export function orderList({ pageNum, pageSize, orderState, queryWords }) {
  return request.get("/order/order/queryOrderList", {
    pageNum,
    pageSize,
    orderState,
    queryWords,
  });
}

// 详情页面
export function contractlist() {
  return request.get("/order/order/basic/contractInfo/query/basic/contract/list");
}

export function Timeout(orderNumber) {
  return request.get(`/order/order/timeoutJudgement/${orderNumber}`);
}

export function orderLogistics(data) {
  return request.get("/order/order/orderLogistics?logisticsCompanyName="+data.logisticsCompanyName +'&expressNumber='+data.expressNumber + '&orderNumber=' + data.orderNumber);
}

// 确认收货
export function UpdateOrder(orderNumber) {
  return request.get(`/order/order/confirmReceipt/${orderNumber}`);
}

export function allMasterSn(orderNumber) {
  return request.get(`/order/order/allMasterSn/${orderNumber}`);
}

export function Deletecontract(id) {
  return request.delete(`/order/order/basic/contractInfo/delete/${id}`);
}

export function contractConfirm(data) {
  return request.post("/order/order/sales/contract/confirm",data);
}

export function contractupdate(data) {
  return request.post("/order/order/basic/contractInfo/save/or/update", data);
}

export function basiccontract(id) {
  return request.post(`/order/order/basic/contractInfo/set/default/basic/contract/${id}`);
}

export function contractGenerate(data) {
  return request.post("/order/order/sales/contract/generate",data);
}

export function contractList(data) {
  return request.get("/order/order/sales/contract/query/list?pageNum=" + data.pageNum +'&pageSize=' + data.pageSize + '&param=' + data.param + '&status=' + data.status);
}

export function contractOrderList(data) {
  return request.get(`/order/order/sales/contract/query/order/list/${data.pageNumber}/${data.pageSize}`);
}
