<template>
  <el-dialog v-model="dialogOpen" :show-close="false" width="480" height="268">
    <template #header="{ close, titleId, titleClass }">
      <div class="my-header">
        <h4 :id="titleId" :class="titleClass" class="open1">审核未通过原因</h4>
        <p @click="close" class="open2"></p>
      </div>
    </template>
    <div>
      <p class="KD">
        <span>{{ checkValue.processReason }}</span>
      </p>
    </div>
    <template #footer>
      <div class="my-header">
        <el-button @click="openTitle" class="open3"> 确定</el-button>
      </div>
    </template>
  </el-dialog>
</template>

<script setup>
import { ref, defineProps, defineEmits } from "vue";
defineProps({
  checkValue: {
    type: Object,
  },
});
const dialogOpen = ref(false);

let emits = defineEmits();
const openTitle = () => {
  console.log("123");
  emits("offTitle");
};
</script>

<style lang="less" scoped>
.my-header {
  height: 58px;
  // border-bottom: 1px solid #e1e1e1;
  display: flex;
  justify-content: space-between;
  .open1 {
    font-size: 18px;
    font-weight: 400;
    color: #333333;
  }
  .open2 {
    display: inline-block;
    width: 14px;
    height: 14px;
    background-image: url("../../../assets//images/order/ch.png");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    cursor: pointer;
    &:hover {
      width: 30px;
      height: 30px;
      background-image: url("../../../assets//images/order/hch.png");
      margin-top: -8px;
      margin-right: -10px;
    }
  }
}
.open3 {
  width: 180px;
  height: 40px;
  background: #ce1200;
  font-size: 12px;
  font-weight: 400;
  color: #ffffff;
  margin: 0 auto;
  border: #ce1200;
}
.KD,
.KD1 {
  font-size: 15px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #666666;
  text-align: center;
}
</style>
