import request from "@/utils/request";

// 我的订单列表
export function orderList({ pageNum, pageSize, orderState, queryWords }) {
  return request.get("/order/order/queryOrderList", {
    pageNum,
    pageSize,
    orderState,
    queryWords,
  });
}

// 详情页面
export function particularsOrder(orderNumber) {
  return request.get(`/order/order/orderByOrderNumber/${orderNumber}`);
}

// 详情页面
export function addedDetail(id) {
  return request.get(`/order/value/added/detail/${id}`);
}


// 立即支付判断是否超时
export function Timeout(orderNumber) {
  return request.get(`/order/order/timeoutJudgement/${orderNumber}`);
}



export function orderLogistics(data) {
  return request.get("/order/order/orderLogistics?logisticsCompanyName="+data.logisticsCompanyName +'&expressNumber='+data.expressNumber + '&orderNumber=' + data.orderNumber);
}

// 确认收货
export function UpdateOrder(orderNumber) {
  return request.get(`/order/order/confirmReceipt/${orderNumber}`);
}

export function allMasterSn(orderNumber) {
  return request.get(`/order/order/allMasterSn/${orderNumber}`);
}

export function materials(orderNumber,productId,serialNum) {
  return request.get(`/order/order/materials/${orderNumber}/${productId}/${serialNum}`);
}

// 删除收货地址
export function DeleteAddress(id) {
  return request.delete(`/order/address/deleteAddress/${id}`);
}

// 删除发票地址
export function DeleteAddressInvoice(id) {
  return request.delete(`/order/invoice/deleteCustomerInvoice/${id}`);
}
// 修改补充订单地址
export function addSaveAddress(data) {
  return request.post("/order/address/updateOrderAddress", data);
}

// 修改新增发票
export function ModifyNewInvoice(data) {
  return request.post("/order/invoice/updateOrderInvoice", data);
}
// 查看发票
export function InvoiceByOrderNumber(orderNumber) {
  return request.get(`/order/invoice/invoiceByOrderNumber/${orderNumber}`);
}
// 审核未通过
export function PayTran(orderNumber) {
  return request.get(`/pay/payTransfer/getTransfer/${orderNumber}`);
}

// 查询商品SN
export function SnCode(data) {
  return request.get(
    `/order/order/master/sn/codes?orderNumber=` +
      data.orderNumber +
      "&productId=" +
      data.productId +
      "&serialNum=" +
      data.serialNum
  );
}

// 查询商品物料
export function ProductMaterials(id) {
  return request.get(`/order/order/materials/` + id);
}

//查询服务订单列表
export function addedList(data) {
  return request.get(`/order/value/added/list`,data);
}
